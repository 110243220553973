$primary: #d41e44;
$secondary: #ffd6d9;
$primary-dark: #11fdbe;

$white: #fff;
$black: #000;
$darkgray: #999;
$lightgray: #ccc;

$txt: #000;
$txtRevert: #000;
$altTxt: #f8f6f0;
